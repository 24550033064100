import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { axios } from "../../../boot";
import { useShowFullName } from "../../../hooks";
import { eghtesadPro, serverStatus } from "../../../constants";
import profileIcon from "../../../assets/icons/profile/Profile.svg";
import Score from "./Score";
import "./index.scss";
// import Sms from "./Sms";
import CurrentPlan from "./CurrentPlan";
import { ChooseVps, PlanModal } from "../../../components";
import Context from './_context'
import ChoosePlan from "./ChoosePlan";
import Condition from "./Condition";
import Payment from "./Payment";
import Receipt from "./Receipt";
import ShowPlan from "./ShowPlan";
import UsdPayment from "./UsdPayment";
import ZarinPayment from "./ZarinPayment";
import PlanBill from "./PlanBill";
import ServerBill from "./ServerBill";
import LinkAndLicense from "./LinkAndLicense";
import { handleDate } from "../../../methods";

export default function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((s) => s.profile);
  const showFullName = useShowFullName();
  const [show, setShow] = useState(false)
  const [activePage, setActivePage] = useState("choosePlan")
  const [planDetails, setPlanDetails] = useState({})
  let { days, res } = handleDate(profile?.expiresIn)
  const setProfile = (data) => {
    dispatch({ type: "SET_PROFILE", data });
  };
  const setIsLogged = (data = false) => {
    dispatch({ type: "SET_IS_LOGGED", data });
  };
  const logout = () => {
    const text = "آیا از درخواست خود مطمئن هستید؟";
    if (window.confirm(text)) {
      delete axios.defaults.headers["x-auth-token"];
      localStorage.removeItem("token");
      navigate("/", { replace: true });
      setIsLogged(false);
      setProfile(null);
    }
  };
  return (
    <div className="Profile">
      <div className="Profile-info bg-black d-flex flex-center w-100 p-4">
        <Container className="mb-4">
          <Row className="d-flex justify-content-md-between align-items-center px-0">
            <Col className="d-flex align-items-start align-items-xl-center px-0" xs="12" lg="6">
              <img width="50" src={profileIcon} alt="profileIcon" />
              <div className="mx-2">
                <p className="text-white fs-24 fw-500 ">
                  <span className="fs-24 fw-700 ">{showFullName()}</span>عزیز، خوش آمدید به مارول ترید  👋
                </p>
                <span className="text-secondary fs-12 fw-400 text-white">
                  شماره موبایل 0{profile.phone?.substring(3)}
                </span>
              </div>
            </Col>
            <Col className="d-flex align-items-center justify-content-start justify-content-lg-end px-0" xs="12" lg="6">
              <button onClick={() => logout()} className="btn btn-outline-danger rounded-0 fs-14 fw-500 my-4 mx-2">خروج از حساب کاربری</button>
              {profile?.authentication == 0 && <Link to="/authentication" className="btn btn-outline-success rounded-0 fs-14 fw-500">احراز هویت</Link>}
            </Col>
          </Row>
        </Container>
        {/* <h3 className="text-info py-4 d-block text-center">پروفایل</h3>
      <div className="info d-flex flex-column flex-lg-row align-items-center col-gap-4 row-gap-3 bg-light-gray rounded shadow-sm px-5 py-4">
        <img width="50" src={profileIcon} alt="profileIcon" />
        <span className="text-secondary">
          شماره موبایل 0{profile.phone?.substring(3)}
        </span>
        <button
          onClick={logout}
          className="text-danger d-flex flex-center me-0 me-lg-auto"
        >
          خروج از حساب کاربری
          <img width="20" className="me-1" src={logoutIcon} alt="logoutIcon" />
        </button>
      </div>
      <div className="w-100 d-flex flex-center bg-light-gray rounded-1 shadow-sm mt-5 py-4">
        <Link to="/active-services" className="btn btn-lg btn-outline-success">
          مشاهده سرویس‌های فعال
        </Link>
      </div>
      <div className="d-flex flex-column bg-light-gray rounded-1 shadow-sm overflow-hidden mt-5">
        <div className="w-100 bg-white text-center text-info py-4">
          خرید های قبلی شما
        </div>
        <Row className="w-100 py-4">
          {orders.map((order, index) => (
            <React.Fragment key={index}>
              <Col xs="12">
                <div className="date-box d-flex flex-center w-100 bg-dark bg-opacity-25 py-1">
                  <span
                    className="bg-white py-1 px-2 rounded-pill fs-7 "
                    dir="ltr"
                  >
                    {moment.miladiToShamsi({
                      date: order.createdAt,
                      format: "jYYYY/jMM/jDD HH:mm:ss",
                    })}
                    {" :"}
                    تاریخ خرید
                  </span>
                </div>
              </Col>
              {order.content.map((item, index) => (
                <Col xs="12" md="6" lg="5" xl="4" key={index}>
                  <div className="w-100 rounded-3 border border-primary">
                    <div className="w-100 d-flex flex-center text-center col-gap-3 border-bottom border-primary fs-5 p-3">
                      <span className="text-info">{item.name} </span>
                      <span className="text-secondary">سالانه</span>
                    </div>
                    <div className="row w-100 p-3 text-secondary">
                      {serverInfo(item).map((data, index) => (
                        <React.Fragment key={index}>
                          <div className="col-6">{data.label}</div>
                          <div dir="ltr" className="col-6 font-en text-end">
                            {data.value}
                          </div>
                        </React.Fragment>
                      ))}
                      <div className="col-12 d-flex flex-center">
                        {serverStatus.badge(order.status)}
                      </div>
                      <div className="col-6 text-primary mt-4">قیمت کل</div>
                      <div className="col-6 text-primary font-en mt-4">
                        {item.totalPrice.toLocaleString()} تومان
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </React.Fragment>
          ))}
        </Row>
      </div> */}

      </div>

      <div className="pardakht-section">
        <Container className="p-0">
          <Row className="p-0 d-flex align-items-start">
            <Col xs="12" lg="4">
              <CurrentPlan onShow={setShow} planId={profile?.plan} setActivePage={setActivePage} days={days} res={res} />
              {/* <Sms /> */}
            </Col>
            <Col xs="12" lg="4" className="">
              {(eghtesadPro.includes(profile?.plan) && days > 3 ) &&  <LinkAndLicense />}
              <ShowPlan setActivePage={setActivePage} onShow={setShow} />
            </Col>
            <Col xs="12" lg="4"><Score profile={profile} /></Col>
          </Row>
        </Container>
      </div>
      <Context.Provider value={[activePage, setActivePage, planDetails, setPlanDetails]}>
        <PlanModal closeBtn={true} size="xl" show={show} onHide={setShow}>
          <Container>

            {activePage === "choosePlan" && <ChoosePlan />}
            {activePage === "condition" && <Condition />}
            {activePage === "payment" && <Payment />}
            {activePage === "usdPayment" && <UsdPayment />}
            {activePage === "zarinPayment" && <ZarinPayment />}
            {activePage === "receipt" && <Receipt />}
            {activePage === "PlanBill" && <PlanBill />}
            {activePage === "ServerBill" && <ServerBill />}
            {activePage === "chooseVps" && <ChooseVps />}
          </Container>
        </PlanModal>
      </Context.Provider>
    </div >
  );
}

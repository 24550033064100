export default function reducer(state = 3, action) {
  switch (action.type) {
    case "Not-Auth":
      return 0;
    case "Accept-Auth":
      return 2;
    case "Check-Auth":
      return 1;
    default:
      return state;
  }
}

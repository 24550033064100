import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, Form, Input, Select } from '../../../components';
import { IoWarningOutline } from "react-icons/io5";
import { mentors, rules } from '../../../constants';
import { Col, Container } from 'react-bootstrap';
import { axios } from '../../../boot'
import { toast } from '../../../methods';
import { useNavigate } from 'react-router';
import logo from '../../../assets/logo/landingLogo.svg'
import store from '../../../redux'
const Authentication = () => {
    const navigation = useNavigate()
    const profile = useSelector((s) => s.profile);
    const isLoggedIn = useSelector((s) => s.isLoggedIn);
    const [data, setData] = useState({})
    const gender = [
        { name: "زن", id: "male" },
        { name: "مرد", id: "female" },
    ]

    const formControls2 = [
        {
            label: "نام",
            labelColor: "black",
            border: true,
            borderColor: "jet",
            state: "firstName",
            rules: rules.notEnglishKeyboard,

        },
        {
            label: "نام خانوادگی",
            labelColor: "black",
            border: true,
            borderColor: "jet",
            state: "lastName",
            rules: rules.notEnglishKeyboard,

        },
        // {
        //     label: "رمزعبور",
        //     state: "password",
        //     type: "password",
        //     border: true,
        //     borderColor: "jet",
        //     rules: rules.optional,
        //     labelColor: "black"
        // },
        {
            label: "ایمیل",
            state: "email",
            labelColor: "black",
            border: true,
            borderColor: "jet",
        },
        {
            label: "کدملی",
            state: "codeMelli",

            rules: rules.nationalId,
            labelColor: "black",
            border: true,
            borderColor: "jet",
        },
        {
            label: "شغل",
            state: "job",
            labelColor: "black",
            border: true,
            borderColor: "jet",
            rules: rules.notEnglishKeyboard,
        },
        {
            label: "درآمد دلاری",
            state: "inventory",
            labelColor: "black",
            type: "number",
            border: true,
            borderColor: "jet",
        },
        {
            label: "تاریخ تولد",
            state: "birthday",
            type: "date",
            labelColor: "black",
            border: true,
            borderColor: "jet",
        },
        {
            tag: Select,
            items: gender,
            label: "جنسیت",
            state: "gender",
            labelColor: "black",
            border: true,
            borderColor: "jet",
        },
        {
            label: `شماره موبایل ${profile?.phone?.length > 1 ? "(غیرقابل تغییر)" : ""}`,
            labelColor: "black",
            border: true,
            borderColor: "jet",
            state: "phone",
            rules: rules.optional,
            readOnly: profile?.phone?.length > 1 ? true : false,
            dir: "ltr"
        },
        {
            tag: Select,
            items: mentors,
            label: "منتور",
            state: "mentor",
            labelColor: "black",
            border: true,
            borderColor: "jet",
        },
    ];

    const handleSubmit = () => {
        const url = "/v2/users/authentication"
        let body = {}
        body.firstName = data.firstName
        body.lastName = data.lastName
        body.mentor = data.mentor
        body.phone = profile?.phone
        body.gender = data.gender
        body.birthday = data.birthday
        body.inventory = data.inventory
        body.job = data.job
        body.codeMelli = data.codeMelli
        body.email = data.email
        axios.put(url, body).then(({ data }) => {
            let text = "اطلاعات با موفقیت ثبت شد لطفا مجددا وارد شوید"
            toast({ text })
            delete axios.defaults.headers["x-auth-token"];
            localStorage.removeItem("token");
            localStorage.removeItem("priviliages");
            localStorage.removeItem("role");
            localStorage.clear();
            store.dispatch({ type: "SET_IS_LOGGED", data: false });
            return navigation("/login")
        })
    }

    useEffect(() => {
        // if (!isLoggedIn) {
        //     delete axios.defaults.headers["x-auth-token"];
        //     localStorage.removeItem("token");
        //     localStorage.removeItem("priviliages");
        //     localStorage.removeItem("role");
        //     localStorage.clear();
        //     store.dispatch({ type: "SET_IS_LOGGED", data: false });
        //     let text = "لطفا ابتدا وارد سایت شوید"
        //     toast({ text })
        //     return navigation("/")
        // } else {

            setData(profile)
        // }
    }, [profile])
    return (
        <Container style={{ minHeight: "100vh" }} className='w-100 d-flex flex-column align-items-center py-4 '>
            <div className='w-100 my-2'>
                <p className='text-danger text-start'><IoWarningOutline /> لطفا اطلاعات زیر را با دقت تکمیل کنید</p>
            </div>
            <Form onSubmit={() => handleSubmit()} className='row rounded  bg-white border border-primary border-2 '>
                <Col className='d-flex align-items-center justify-content-center py-4' xs="12">
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <img src={logo} alt='logo' loading='lazy' />
                        <p className='fw-700 fs-18 text-black mt-2'>مارول ترید</p>

                    </div>
                </Col>
                {formControls2.map((e, index) =>
                    <Col key={index} xs="12" md="6" lg="4">
                        {React.createElement(e.tag ?? Input, {
                            ...e,
                            key: e.state,
                            value: data && data[e.state],
                            className: "",
                            setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
                        })}
                    </Col>
                )}
                <div className="d-flex justify-content-start flex-wrap align-items-center my-4">
                    <Button variant='black' className='mx-2' outline={true} type="submit">ذخیره اطلاعات</Button>
                </div>
            </Form>
        </Container>
    )
}

export default Authentication
import React, { useEffect, useState } from 'react'
import { axios } from '../../../boot'
import { Button } from '../../../components'
import { copyText, toast } from '../../../methods'
const LinkAndLicense = () => {
    const [data, setData] = useState({})
    const getLicense = () => {
        const url = "/v2/users/createclasslisence"
        axios.get(url).then(({ data }) => {
            setData(data)
        })
    }
    const copyCode = (id) => {
        const value = id;
        copyText(value)
            .then(() => {
                const text = "کپی شد.";
                toast({ text });
            })
            .catch(() => {
                const text = "خطا در کپی کد معرف لطفا مجددا تلاش کنید.";
                toast({ text, type: "error" });
            });
    };
    useEffect(getLicense, [])
    return (
        <div className="w-100 bg-harp border border-black mb-2 p-4">
            <p className='text-black fw-700 fs-20'>لایسنس و لینک کلاس اقتصاد2 پرو</p>
            <div className='d-flex flex-column align-items-center justify-content-center'>
                <Button className='w-100 my-2 text-white py-1' onClick={() => copyCode(data?.eco2classLink)}>دریافت لینک کلاس آنلاین</Button>
                <Button className='w-100 my-2 text-white py-1' onClick={() => copyCode(data?.eco2spotLisence)}>دریافت لایسنس اسپات پلیر</Button>
            </div>
        </div>
    )
}

export default LinkAndLicense
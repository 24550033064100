import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form as Formm } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { Input, Button, Form, Select, Modal, MultiSelect } from "../../../../components";
import { axios } from "../../../../boot";
import { toast } from "../../../../methods";
import { useGetPlan } from "../../../../hooks";
import { authenticationStatus, mentors, rules } from "../../../../constants";

export default function User() {
  const { id } = useParams();
  const isNew = id === "new"
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [userChannels, setUserChannels] = useState([])
  const [channelsId, setChannelsId] = useState([])
  const plan = useGetPlan()
  const getData = () => {
    if (!isNew) {
      const url = `/users/${id}/getUsers`;
      axios.get(url).then(({ data }) => {
        setData(data);
        setChannelsId([...data?.channelsNotify])
      });
    }
  };
  const getUserChannels = () => {
    if (!isNew) {
      const url = `/channels/public/${id}/getChannels`
      axios.get(url).then(({ data }) => {
        setUserChannels([...data[0]?.channels])
      })
    }
  }
  const files = ["logo"];
  const addUser = () => {
    const url = "/authentication/register"
    axios.post(url, data).then(() => {
      let text = "کاربر با موفقیت افزوده شد"
      toast({ text });
      navigate(-1, { replace: true });
    });
  }
  const updateUser = () => {
    const url = `/users/${id}/editUser`
    const body = { ...data, channelsNotify: channelsId }
    axios.patch(url, data).then(({ data }) => {
      let text = "کاربر با موفقیت ویرایش شد"
      toast({ text });
      navigate(-1);
    })
  }
  const devices = [
    {
      name: "android",
      id: "android"
    },
    {
      name: "ios",
      id: "ios"
    },
  ]
  const fromControls = [
    {
      state: "firstName",
      label: "نام",
    },
    {
      state: "lastName",
      label: "نام خانوادگی",
    },
    {
      state: "phone",
      label: "شماره همراه",
    },
    {
      state: "imei",
      label: "imei",
    },
    {
      state: "email",
      label: "ایمیل",
    },
    {
      state: "job",
      label: "شغل",
      rules : rules.optional
    },
    {
      state: "codeMelli",
      label: "کدملی",
      rules : rules.optional
    },
    {
      type : "date",
      state: "birthday",
      label: "تاریخ تولد",
      rules : rules.optional
    },
    {
      state: "inventory",
      label: "درآمد دلاری",
      rules : rules.optional
    },
    {
      tag: Select,
      state: "mentor",
      label: "منتور",
      items : mentors,
      rules : rules.optional
    },
    {
      tag: Select,
      state: "authentication",
      label: "وضعیت احراز هویت",
      items : authenticationStatus
    },
    {
      tag: Select,
      state: "plan",
      label: "پلن",
      items: plan?.filter(e => e.active == true && e._id !== "61ca2cb6927a5772a822653a")?.map(i => ({ name: i.name, id: i._id })),

    },
    {
      tag: Select,
      state: "device",
      label: "دستگاه",
      items: devices,
    },
    {
      type: "date",
      state: "expiresIn",
      label: "تاریخ انقضاء",
      cancelBtn: true
    },
  ]
  const fromControls2 = [
    {
      state: "firstName",
      label: "نام",
    },
    {
      state: "lastName",
      label: "نام خانوادگی",
    },
    {
      state: "phone",
      label: "شماره همراه",
    },
    {
      state: "imei",
      label: "imei",
    },
    {
      state: "email",
      label: "ایمیل",
    },
    {
      state: "job",
      label: "شغل",
      rules : rules.optional
    },
    {
      state: "codeMelli",
      label: "کدملی",
      rules : rules.optional
    },
    {
      type : "date",
      state: "birthday",
      label: "تاریخ تولد",
      rules : rules.optional
    },
    {
      state: "inventory",
      label: "درآمد دلاری",
      rules : rules.optional
    },
    {
      tag: Select,
      state: "mentor",
      label: "منتور",
      items : mentors,
      rules : rules.optional
    },
    {
      tag: Select,
      state: "authentication",
      label: "وضعیت احراز هویت",
      items : authenticationStatus
    },
    {
      tag: Select,
      state: "plan",
      label: "پلن",
      items: plan?.filter(e => e.active == true && e._id !== "61ca2cb6927a5772a822653a")?.map(i => ({ name: i.name, id: i._id })),

    },
    {
      tag: Select,
      state: "device",
      label: "دستگاه",
      items: devices,
    },
    {
      state: "password",
      label: "رمز عبور",
      types: "password"
    },
  ]
  const deleteUser = () => {
    const url = `/users/${id}/deleteUser`
    axios.delete(url).then(() => {
      let text = "کاربر با موفقیت حذف شد"
      toast({ text });
      navigate(-1);
    });
  }
  const removeFromList = (id) => {
    let test = channelsId?.filter(e => e !== id)
    setChannelsId(test)
  }
  const addToList = (id) => {
    let test = [...channelsId, id]
    setChannelsId(test)
  }
  const handleForm = {
    true : fromControls2,
    false : fromControls
  }
  useEffect(getUserChannels, []);
  useEffect(getData, []);
  return (
    <Container className="Product">
      <Form onSubmit={isNew ? addUser : updateUser}>
        <Row>
          {handleForm[isNew]?.map((item, index) => (
            <Col
              key={index}
              xs="12"
              md="6"
              lg="4"
            >
              {React.createElement(item.tag ?? Input, {
                rules: rules.required,
                value: data[item.state],
                setValue: (val) =>
                  setData((p) => ({ ...p, [item.state]: val })),
                ...item,
              })}
            </Col>
          ))}
          {/* <Col
            xs="12"
            lg="12" >
            <MultiSelect selectedOptions={selectOption} setSelectedOptions={setSelectedOption} label="کانال" options={AllChannels} />
          </Col> */}
          {!isNew &&
            <Col xs="12">
              <p>کانال ها</p>
              <div className="row my-4 d-flex justify-content-start">
                {userChannels?.map((e) =>
                  <Col key={e._id} className="bg-white shadow p-2" xs="12" md="6" lg="4">
                    <Formm.Check
                      
                      className='w-100'
                      type="switch"
                      id={e._id}
                      label={e?.name}
                      checked={channelsId?.find(item => item == e?._id) ? false : true}
                      onClick={channelsId?.find(item => item == e?._id) ? () => removeFromList(e._id) : () => addToList(e._id)}
                    />
                  </Col>

                )}
              </div>
            </Col>
          }

          <Col xs="12" className="d-flex flex-center">
            <Button type="submit" variant="primary" className="text-light">
              {isNew ? " ثبت کاربر جدید" : "ثبت تغییرات"}
            </Button>
          </Col>
        </Row>
      </Form>
      {!isNew &&
        <div className="d-flex flex-center flex-wrap my-4">
          <Button onClick={() => deleteUser()} variant="danger text-white" className="m-2 ">حذف کاربر</Button>
        </div>
      }
      {/* <Modal show={show} onHide={setShow}>
        <img className="w-100" src={`data:image/jpeg;base64,${data?.image}`} alt="qrCode" />
      </Modal> */}
    </Container>
  );
}

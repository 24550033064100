import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes, useLocation, useNavigate } from "react-router-dom";
import routes from "./routes";
import { clearCaches, logout, setPrototypes, toast } from "./methods";
import { Loading } from "./components";
import { axios } from "./boot";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

setPrototypes();
export default function App() {
  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const loading = useSelector((s) => s.loading.length !== 0);
  const isLogged = useSelector((s) => s.isLogged);
  const auth = useSelector((s) => s.auth);
  const role = useSelector((s) => s.role);
  const elements = useRoutes(routes(isLogged, role, auth));

  const setProfile = (data) => {
    dispatch({ type: "SET_PROFILE", data });
  };
  const setRole = (data) => {
    dispatch({ type: "SET_ROLE", data });
  };
  const setCartCount = (data) => {
    dispatch({ type: "SET_CART_COUNT", data });
  };
  const setAuth = (data = 0) => {
    if (data == 0) {
      return dispatch({ type: "Not-Auth" });
    } else if (data == 1) {
      return dispatch({ type: "Check-Auth" });
    } else {
      return dispatch({ type: "Accept-Auth" });
    }
  };
  const scrollToTop = () => window.scrollTo(0, 0);
  const getProfile = () => {
    if (isLogged) {
      const url = "/users/siteme";
      axios.get(url).then(({ data }) => {
        setAuth(data?.authentication)
        setProfile(data);
        setRole(data.role);
        if (data?.authentication == 0) {
          let text = "لطفا اطلاعات خواسته شده را با دقت تکمیل کنید"
          toast({text})
          return navigate("/authentication")
        }   
        getCartCount();     
      });
    }
  };
  const getCartCount = () => {
    if (isLogged) {
      const url = "/carts/count";
      axios.get(url).then(({ data }) => {
        setCartCount(data);
      });
    }
  };

  useEffect(scrollToTop, [location.pathname]);
  useEffect(getProfile, [isLogged]);
  useEffect(clearCaches, []);
  return (
    <div className="App">
      <h1 className="d-none">
        انتخاب مطمئن شما برای مشاوره و سرمایه گذاری در بازار های مالی
      </h1>
      <Toaster position="top-left" reverseOrder={false} />
      {elements}
      {loading && <Loading />}
      <button
        onClick={scrollToTop}
        className="btn btn-info btn-sm bg-info bg-opacity-50 bi bi-chevron-double-up btn-scroll-top position-fixed rounded-circle p-0"
      />
    </div>
  );
}


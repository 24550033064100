import { combineReducers, createStore } from "redux";
import loading from "./reducers/loading";
import isLogged from "./reducers/isLogged";
import cartCount from "./reducers/cartCount";
import profile from "./reducers/profile";
import role from "./reducers/role";
import auth from './reducers/auth'
const rootReducer = combineReducers({
  loading,
  isLogged,
  cartCount,
  profile,
  role,
  auth
});
const store = createStore(rootReducer);
export default store;
